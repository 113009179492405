import React from 'react';
import './Contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faGithub,faLinkedin } from '@fortawesome/free-brands-svg-icons';

function contact(props) {
  return (
    <div className="contact">
      <ul>
        <li>
          <div>
            <a href="mailto:michaelyu0123@gmail.com?subject=Inquiry%20from%20michaelyustudios.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faEnvelope} size={props.size} />
              {props.text && 
                <span><br/>Email</span>
              }
            </a>
          </div>
        </li>
        <li>
          <div>
            <a href="https://www.linkedin.com/in/michael-yu-b361624b/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} size={props.size} />
              {props.text && 
                <span><br/>LinkedIn</span>
              }
            </a>
          </div>
          
        </li>
        <li>
          <div>
            <a href="https://github.com/michaelyu0123" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faGithub} size={props.size} />
              {props.text && 
                <span><br/>Github</span>
              }
            </a>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default contact;
