import React from 'react';
import { Collapse,Navbar,NavbarToggler,NavbarBrand,Nav,NavItem } from 'reactstrap';
import { Link } from 'react-scroll'
import './Navigation.css';
import brand from '../images/brand.png';


export default class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <div>
        <Navbar color="light" light expand="md">
          <NavbarBrand href="/"><img className="brand" src={brand} alt="MY"/></NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
            <NavItem>
                <Link to="about" smooth={true} duration={500} >About Me</Link>
              </NavItem>
              <NavItem>
                <Link to="what-i-do" smooth={true} duration={500} >Who Am I</Link>
              </NavItem>
              <NavItem>
                <Link to="contact-me" smooth={true} duration={500} >Contact Me</Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}