import React from 'react';
import './reset.css';
import './App.css';
import myPhoto from './images/michael.png';
import Contact from './components/Contact';
import Navigation from './components/Navigation';
import { Link,Element } from 'react-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';

function App() {
  return (
    <div className="App">
      <Navigation/>
      <header className="App-header">
        <div className="contents">
          <img className="myPhoto" src={myPhoto} alt="Michael Yu"/>
          <p className="main-text">Michael Yu</p>
          <hr/>
          <p className="sub-text">Front-end Developer</p>
          <Contact size={"2x"}/>
        </div>
        <Link to="about" smooth={true} duration={500} className="down-arrow" >
          <FontAwesomeIcon icon={faChevronCircleDown} size="2x" />
        </Link>
      </header>
      <Element name="about">
        <section className="about">
          <div className="contents">
            <h2>About me:</h2>
            <hr/>
            <p>Web development is my passion. It is an avenue to bring wonderful ideas into reality.</p>
            <p>Based out of Austin, Texas, I'm currently a software development engineer at Adobe, developing the next greatest and latest in e-commerce.</p>
          </div>
        </section>
      </Element>
      <Element name="what-i-do">
        <section className="what-i-do">
          <div className="contents">
            <h2>What I do:</h2>
            <hr/>
            <p>Develop what you need.</p>
            <p>I can create your website using the best technologies available based on your needs and deliver a digital experience that is above and beyond.</p>
          </div>
        </section>
      </Element>
      <Element name="contact-me">
        <section className="contact-me">
          <div className="contents">
            <h2>Contact Me</h2>
            <hr/>
            <p>Need to turn an idea into reality?</p>
            <Contact text={true} size={"4x"}/>
          </div>
        </section>
      </Element>
      <footer>
        <p>Website designed and developed by Michael Yu</p>
        <p>Background SVG made with <a href="https://trianglify.io/">https://trianglify.io/</a></p>
      </footer>
    </div>
  );
}

export default App;
